
.nestable-item, .nestable-item-copy {
    margin: 5px 0 0;
}

.nestable-item.is-dragging:before {
    background: #838383;
    border: 1px dashed #8c8c8c;
    border-radius: 3px;
}

.nestable-item .nestable-list, .nestable-item-copy .nestable-list {
    margin-top: 5px;
}