/*************/
/* flickity  */
/*************/
.carousel-cell {
    /* width: 100%; full width */
    /* height: 280px; height of carousel */
    /* margin-right: 10px; */
    /* border-radius: 10px; */
}
  
.flickity-button {
    background: transparent;
}
  
.flickity-button:hover {
    background: transparent;
}
  
.flickity-prev-next-button {
    width: 45px;
    height: 45px;
    border-radius: 55px;
}
  
.flickity-button-icon {
    fill: white;
}
  
/* position dots in carousel */
.flickity-page-dots {
    bottom: 10px !important;
}
  
/* white circles */
.flickity-page-dots .dot {
    width: 8px;
    height: 8px;
    opacity: 0.5;
    background: transparent;
    border: 2px solid white;
}

/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
    opacity: 0.7;
    background: white;
}
  